var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        height: "100%",
        display: "flex",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "div",
        { staticClass: "members-role-search" },
        [
          _c(
            "el-input",
            {
              staticClass: "name-keyword-input",
              attrs: {
                maxlength: 20,
                size: "mini",
                placeholder: "搜索顾问姓名",
              },
              on: { change: _vm.search },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            },
            [
              _c("el-button", {
                staticClass: "name-search-btn",
                attrs: {
                  slot: "append",
                  type: "primary",
                  icon: "el-icon-search",
                },
                on: { click: _vm.search },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("members-role-set-table", {
        ref: "membersRoleSetTable",
        attrs: { tableData: _vm.list },
        on: { "edit-members-role": _vm.handleEditMembersRole },
      }),
      _vm.page.total > 20
        ? _c(
            "div",
            { staticClass: "work-table-footer" },
            [
              _c(
                "el-pagination",
                {
                  staticClass: "el-pagination-workTable",
                  attrs: {
                    "current-page": _vm.page.current,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _vm.page.size,
                    layout: "total, sizes, prev, pager, next, slot",
                    total: _vm.page.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "work-table-footer" }),
      _c("edit-members-role-dialog", {
        ref: "editMembersRoleDialog",
        on: { "update-members-role": _vm.handleUpdateMembersRole },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }