var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "members-role-set-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            height: "700",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "realName",
              align: "center",
              "show-overflow-tooltip": true,
              label: "姓名",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "teamList",
              align: "center",
              "show-overflow-tooltip": true,
              label: "所属团队",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.teamList, function (item, index) {
                    return _c("span", { key: index }, [
                      index != "0" ? _c("i", [_vm._v(" 、 ")]) : _vm._e(),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.teamName) +
                          "\n                "
                      ),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roleList",
              align: "center",
              label: "成员角色",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return _vm._l(
                    _vm.filterRole(row.roleList),
                    function (item, index) {
                      return _c(
                        "span",
                        { key: item.code },
                        [
                          [
                            index != "0" ? _c("i", [_vm._v(" , ")]) : _vm._e(),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.name) +
                                "\n                    "
                            ),
                          ],
                        ],
                        2
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "icon-wrapper edit-icon-wrapper",
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.$index, scope.row)
                          },
                        },
                      },
                      [
                        _c("font-icon", {
                          staticClass: "customer-icon customer-edit-icon",
                          attrs: { href: "#icon-edit" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("div", [
              _c("span", { staticClass: "empty-img" }),
              _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }