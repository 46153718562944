var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "edit-members-role-dialog",
      attrs: {
        title: "成员角色设置",
        width: "480px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "set-role" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.baseRoleCode,
                callback: function ($$v) {
                  _vm.baseRoleCode = $$v
                },
                expression: "baseRoleCode",
              },
            },
            _vm._l(_vm.rolesArr, function (item, idx) {
              return _c(
                "el-checkbox",
                { key: "r_" + idx, attrs: { label: item.roleCode } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(item.roleName) +
                      "\n            "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.isDepartmentManageAudit
        ? _c("div", { staticClass: "item org-auditor" }, [
            _c("span", { staticClass: "switch-text" }, [
              _vm._v("组织架构审核人"),
            ]),
            _c(
              "p",
              { staticClass: "edit-members-role-list" },
              _vm._l(
                _vm.selectedDepartmentReviewerCustomerList,
                function (item, index) {
                  return _c("span", { key: "mr_" + index }, [
                    _c("i", { staticClass: "members-role-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          return _vm.handleDepartmentReviewerCustomer(index)
                        },
                      },
                    }),
                  ])
                }
              ),
              0
            ),
            _c(
              "p",
              { staticClass: "edit-members-role-select" },
              [
                _c("span", [_vm._v("选择公司")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      size: "mini",
                      filterable: "",
                    },
                    on: { change: _vm.handleSelectDepartmentReviewerCustomer },
                    model: {
                      value: _vm.departmentReviewerValue,
                      callback: function ($$v) {
                        _vm.departmentReviewerValue = $$v
                      },
                      expression: "departmentReviewerValue",
                    },
                  },
                  _vm._l(_vm.departmentReviewerList, function (item, idx) {
                    return _c("el-option", {
                      key: "opt_mem_" + idx,
                      staticClass: "members-role-select-item",
                      attrs: {
                        title: item.name,
                        label: item.name,
                        value: item.id,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isGeneralCoordinator
        ? _c("div", { staticClass: "item general-coordinator" }, [
            _c("span", { staticClass: "switch-text" }, [_vm._v("综合协调人")]),
            _c(
              "p",
              { staticClass: "edit-members-role-list" },
              _vm._l(
                _vm.selectedGeneralCoordinatorCustomerList,
                function (item, index) {
                  return _c("span", { key: "mrn_" + index }, [
                    _c("i", { staticClass: "members-role-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          return _vm.handleGeneralCoordinatorCustomer(index)
                        },
                      },
                    }),
                  ])
                }
              ),
              0
            ),
            _c(
              "p",
              { staticClass: "edit-members-role-select" },
              [
                _c("span", [_vm._v("选择公司")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      size: "mini",
                      filterable: "",
                    },
                    on: { change: _vm.handleSelectGeneralCoordinatorCustomer },
                    model: {
                      value: _vm.generalCoordinatorValue,
                      callback: function ($$v) {
                        _vm.generalCoordinatorValue = $$v
                      },
                      expression: "generalCoordinatorValue",
                    },
                  },
                  _vm._l(_vm.generalCoordinatorList, function (item, idx) {
                    return _c("el-option", {
                      key: "mrsi_" + idx,
                      staticClass: "members-role-select-item",
                      attrs: {
                        title: item.name,
                        label: item.name,
                        value: item.id,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isBusinessCoordinator
        ? _c("div", { staticClass: "item business-line-coordinator" }, [
            _c("span", { staticClass: "switch-text" }, [
              _vm._v("业务线协调人"),
            ]),
            _c(
              "p",
              { staticClass: "edit-members-role-list" },
              _vm._l(
                _vm.selectedBusinessLineCoordinatorList,
                function (item, index) {
                  return _c("span", { key: "mrn2_" + index }, [
                    _c("i", { staticClass: "members-role-name" }, [
                      _vm._v(_vm._s(item.parentName + "-" + item.label)),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          return _vm.handleBusinessLineCoordinator(index)
                        },
                      },
                    }),
                  ])
                }
              ),
              0
            ),
            _c(
              "p",
              { staticClass: "edit-members-role-select" },
              [
                _c("span", [_vm._v("选择事业线")]),
                _c("el-cascader", {
                  attrs: {
                    options: _vm.businessLineCoordinatorList,
                    filterable: "",
                  },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.businessLineCoordinatorValue,
                    callback: function ($$v) {
                      _vm.businessLineCoordinatorValue = $$v
                    },
                    expression: "businessLineCoordinatorValue",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isLimitedTeamData
        ? _c("div", { staticClass: "item region-hrbp" }, [
            _c("span", { staticClass: "switch-text" }, [
              _vm._v("有限团队数据"),
            ]),
            _c(
              "p",
              { staticClass: "edit-members-role-list" },
              [
                _vm.teamIds.includes("0")
                  ? _c("span", [
                      _c("i", { staticClass: "members-role-name" }, [
                        _vm._v("全部团队"),
                      ]),
                      _c("i", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            return _vm.handleTeam("-1")
                          },
                        },
                      }),
                    ])
                  : _vm._l(_vm.selectedTeamList, function (item, index) {
                      return _c("span", { key: "mrn3_" + index }, [
                        _c("i", { staticClass: "members-role-name" }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-circle-close",
                          on: {
                            click: function ($event) {
                              return _vm.handleTeam(index)
                            },
                          },
                        }),
                      ])
                    }),
              ],
              2
            ),
            _c(
              "p",
              { staticClass: "edit-members-role-select" },
              [
                _c("span", [_vm._v("选择团队")]),
                _c(
                  "el-popover",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.teamTreeLoading,
                        expression: "teamTreeLoading",
                      },
                    ],
                    staticClass: "select-popover",
                    attrs: {
                      "popper-class": "team-select-tree-popover",
                      placement: "bottom-start",
                      width: "386",
                      trigger: "click",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.teamTreePopoverVisible,
                      callback: function ($$v) {
                        _vm.teamTreePopoverVisible = $$v
                      },
                      expression: "teamTreePopoverVisible",
                    },
                  },
                  [
                    _c("el-tree", {
                      attrs: {
                        data: _vm.teamData,
                        props: _vm.defaultProps,
                        "highlight-current": false,
                        "expand-on-click-node": false,
                        "node-key": "teamId",
                        "default-expanded-keys": ["0"],
                      },
                      on: { "node-click": _vm.handleNodeClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ node, data }) {
                              return _c(
                                "span",
                                {
                                  staticClass: "custom-tree-node",
                                  class: _vm.teamIds.includes(data.teamId)
                                    ? "selected"
                                    : "",
                                },
                                [
                                  _c("span", { attrs: { title: node.label } }, [
                                    _vm._v(_vm._s(node.label)),
                                  ]),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        4278572941
                      ),
                    }),
                    _c("el-input", {
                      staticClass: "team-input",
                      attrs: {
                        slot: "reference",
                        readonly: true,
                        placeholder: "请选择",
                        "suffix-icon": "el-icon-arrow-down",
                      },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.editLoading },
              on: { click: _vm.handleClose },
            },
            [_vm._v("取消\n        ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.editLoading },
              on: { click: _vm.confirmHandle },
            },
            [_vm._v("确定\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }