<template>
    <!-- <div class="user-permission-set">
        <div class="members-role"> -->
        <div style="height: 100%; display: flex; flex-direction: column;">
            <div class="members-role-search">
                <el-input
                    class="name-keyword-input"
                    v-model="name"
                    :maxlength="20"
                    size="mini"
                    placeholder="搜索顾问姓名"
                    @change="search">
                    <el-button
                        slot="append"
                        type="primary"
                        icon="el-icon-search"
                        class="name-search-btn"
                        @click="search"
                    ></el-button>
                </el-input>
            </div>
            <members-role-set-table
                ref="membersRoleSetTable"
                :tableData="list"
                @edit-members-role="handleEditMembersRole"
            ></members-role-set-table>
            <!-- <table-page-tab
                :filterIndex="0"
                :pageTabs="pageTabJson"
            ></table-page-tab> -->
            <div class="work-table-footer" v-if="page.total>20">
                <el-pagination
                    :current-page="page.current"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="page.size"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    class="el-pagination-workTable"
                    layout="total, sizes, prev, pager, next, slot"
                    :total="page.total">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>
            <div class="work-table-footer" v-else></div>
            <edit-members-role-dialog ref="editMembersRoleDialog" @update-members-role="handleUpdateMembersRole"></edit-members-role-dialog>
        </div>
        <!-- </div>
    </div> -->
</template>

<script>
import MembersRoleSetTable from './layout/members-role-set-table.vue';
import EditMembersRoleDialog from './components/edit-members-role-dialog.vue';
import UserPermissionService from '#/js/service/userPermissionService.js';
import TablePageTab from '#/component/workTable/table-page-tab.vue';
import pageTabJson from './config/pageTab.json';
import { getAuthUserRoleList, } from "#/js/service/commonService.js";

export default {
    name: "MembersRoleSet",
    components: {
        TablePageTab,
        MembersRoleSetTable,
        EditMembersRoleDialog
    },
    data() {
        return {
            loading: false,
            name: "",
            list: [],
            page: {
                total: 0,
                current: 1,
                size: 20,
            },
            pagerJump: 0,
            params: {
                pageSize: 20,
                offset: 0,
                realName: ""
            }
        }
    },
    computed: {
        pageTabJson() {
            if(this.$store.state.user.userInfo.isCFUser) {
                return pageTabJson.CFUser;
            }else {
                return pageTabJson.notCFUser;
            }
        },
        isHuntingCompanySetPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('UserPermissionSet');
        },
    },
    mounted() {
        if(this.$store.state.user.userInfo.isAdministrator || this.isHuntingCompanySetPermission) {
            this.loading = false;
            this.getList();
        } else {
            shortTips("权限不足，请联系管理员")
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            let _index = tabItems.findIndex(item => item.path === "/membersRoleSet");
            tabItems.splice(_index, 1);
            sessionStorage.setItem('tabItems', JSON.stringify(tabItems));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
    },
    methods: {
        // 点击分页之后，让数据定位到第一条
        setScrollTop(){
            // console.log(this.$refs)
            // console.log(this.$refs.membersRoleSetTable)
            // console.log(this.$refs.membersRoleSetTable.bodyWrapper)
            // this.$nextTick(() => {   
            //     this.$refs.membersRoleSetTable.bodyWrapper.scrollTop = 0;
            // })
            // console.log(document.getElementsByClassName('el-table__body-wrapper'))
            // console.log(document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop)
            document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
        },
        search() {
            this.params.realName = this.name;
            this.getList();
        },
        // 分页
        handleSizeChange(val){
            this.page.current = 1;
            this.page.size = val;
            this.getList();
            this.setScrollTop();
        },
        handleCurrentChange(val){
            this.page.current = val;
            this.getList(true);
            this.setScrollTop();
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.page.current = currentPager;
                this.getList(true);
                this.setScrollTop();
            }
        },
        getList(isSilent = false) {
            let params = Object.assign({}, this.params);
            if(!isSilent) {
                this.page.current = 1;
            }
            params.pageSize = this.page.size;
            params.offset = (this.page.current - 1) * this.page.size;
            this.$refs.membersRoleSetTable.loading = true;
            // UserPermissionService.getMembersRolesList(params).then(res => {
            //     this.$refs.membersRoleSetTable.loading = false;
            //     this.page.total = res.count;
            //     this.list = res.data;
            // }).catch(err => {
            //     this.$refs.membersRoleSetTable.loading = false;
            // })
            getAuthUserRoleList(params)
                .then(res => {
                    this.page.total = res.count;
                    this.list = res.data;
                })
                .finally(() => {
                    this.$refs.membersRoleSetTable.loading = false;
                });
        },
        // 滚动条置顶
        setScrollTop() {
            // this.$nextTick(() => {
            //     this.$refs.membersRoleSetTable.bodyWrapper.scrollTop = 0;
            //
        },
        
        handleEditMembersRole(index, item) {
            this.$refs.editMembersRoleDialog.show({
                index: index,
                data: item
            })
        },
        // 编辑成功后
        handleUpdateMembersRole() {
            // 编辑完成之后重新刷新列表
            this.getList();
        }
    }
}
</script>

<style lang="scss" scope>
// .user-permission-set {
//     height: 100%;
//     .members-role {
//         height: 100%;
//         min-height: 500px;
//         padding: 10px 20px 0 20px;
//         background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
//         display: flex;
//         flex-direction: column;
    // .members-role-page{

        .members-role-search {
            height: 52px;
            background-color: #fff;
            line-height: 52px;
            padding-left: 20px;
            .name-keyword-input {
                width: 244px;
                .el-input-group__append {
                    padding: 0;
                    height: 28px;
                    background-color: $primary;
                    color: #fff;
                    border-color: $primary;
                    .name-search-btn {
                        padding: 0;
                        min-width: 42px;
                        margin: 0;
                        height: 26px;
                        line-height: 26px;
                    }
                }
            }
        }
    // }

        // .work-table-footer {
        //     margin-top: 6px;
        //     height: 28px;
        //     overflow: unset;
        // }
//     }
// }
</style>