<template>
    <div class="members-role-set-table">
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            height="700"
            tooltip-effect="light"
        >
            <el-table-column
                prop="realName"
                align="center"
                :show-overflow-tooltip="true"
                label="姓名"
            ></el-table-column>
            <el-table-column
                prop="teamList"
                align="center"
                :show-overflow-tooltip="true"
                label="所属团队"
            >
                <template slot-scope="scope">
                    <span
                        v-for="(item, index) in scope.row.teamList"
                        :key="index"
                        >
                        <i v-if="index != '0'"> 、 </i >
                        {{ item.teamName }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="roleList"
                align="center"
                label="成员角色"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="{row}">
                    <span
                        v-for="(item, index) in filterRole(row.roleList)"
                        :key="item.code"
                        >
                        <template>
                            <i v-if="index != '0'"> , </i >
                            {{ item.name }}
                        </template>
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <!-- 编辑 -->
                    <span
                        class="icon-wrapper edit-icon-wrapper"
                        @click="handleEdit(scope.$index, scope.row)"
                    >
                        <font-icon
                            class="customer-icon customer-edit-icon"
                            href="#icon-edit"
                        ></font-icon>
                    </span>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
            notRoleList: ['DepartmentReviewer', 'GeneralCoordinator', 'BusinessCoordinator', 'LimitedTeamData', ],
        };
    },
    methods: {
        handleEdit(index, item) {
            this.$emit("edit-members-role", index, item);
        },
        filterRole(rl) {
            return rl.filter((item) => {
                return !this.notRoleList.includes(item.code);
            }) || [];
        },
    }
};
</script>

<style lang="scss" scope>
.members-role-set-table {
//    height: 60.6% !important;
    display: flex;
    // max-height: calc(100vh - 200px);
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    .edit-icon-wrapper {
        cursor: pointer;
        padding-right: 26px;
        position: relative;
        &::after {
            content: "编辑";
            color: $primary;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 23px;
        }
    }
    .customer-icon {
        flex-shrink: 0;
        color: $primary;
        font-size: 20px;
        margin-right: 6px;
        vertical-align: middle;
    }
    .customer-edit-icon {
        margin-left: 20px;
    }

    /deep/ .el-table {
        background-color: transparent;
        .el-table__body-wrapper {
            background-color: #fff;
        }
        th {
            padding: 0;
            height: 42px;
            line-height: 42px;
            background-color: #e8e8e8;
            border-color: #ccc;
            color: #666;
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg")
                    no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>