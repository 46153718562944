<template>
    <el-dialog
        title="成员角色设置"
        width="480px"
        :visible.sync="dialogVisible"
        @close="handleClose"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="edit-members-role-dialog"
    >
        <div class="set-role">
            <!-- <el-radio-group v-model="baseRoleCode" @input="handleRoleChange">
                <el-radio
                    v-for="item in rolesArr"
                    :key="item.roleCode"
                    :label="item.roleCode"
                    >{{ item.roleName }}
                </el-radio>
                <template v-for="item in rolesCFArr">
                    <el-radio
                        v-if="isCFuser"
                        :key="item.roleCode"
                        :label="item.roleCode"
                        >{{ item.roleName }}
                    </el-radio>
                </template>
            </el-radio-group> -->
            <el-checkbox-group v-model="baseRoleCode">
                <el-checkbox 
                    v-for="(item,idx) in rolesArr"
                    :key="'r_' + idx"
                    :label="item.roleCode">
                    {{ item.roleName }}
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <div class="item org-auditor" v-if="isDepartmentManageAudit">
            <span class="switch-text">组织架构审核人</span>
            <!-- :inactive-value="0" -->
            <!-- <el-switch
                v-model="switchOrguditor"
                :width="40"
                active-color="#38bc9d"
                inactive-color="#999999"
                :active-value="'DepartmentReviewer'"
            >
            </el-switch> -->
            <!-- v-if="switchOrguditor == 'DepartmentReviewer'" -->
            <p class="edit-members-role-list">
                <span 
                    v-for="( item, index ) in selectedDepartmentReviewerCustomerList"
                    :key="'mr_' + index"
                >
                    <i class="members-role-name">{{ item.name }}</i>
                    <i
                        class="el-icon-circle-close"
                        @click="handleDepartmentReviewerCustomer(index)"
                    ></i>
                </span>
            </p>
            <p class="edit-members-role-select">
                <span>选择公司</span>
                <!-- :disabled="switchOrguditor !== 'DepartmentReviewer'" -->
                <el-select
                    v-model="departmentReviewerValue"
                    placeholder="请选择"
                    size="mini"
                    
                    @change="handleSelectDepartmentReviewerCustomer"
                    filterable
                >
                    <el-option
                        class="members-role-select-item"
                        v-for="(item,idx) in departmentReviewerList"
                        :title="item.name"
                        :key="'opt_mem_' + idx"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </p>
        </div>
        
        <!-- v-if="isGeneralCoordinator" -->
        <div class="item general-coordinator" v-if="isGeneralCoordinator">
            <span class="switch-text">综合协调人</span>
            <!-- :inactive-value="0" -->
            <!-- <el-switch
                v-model="switchGenCoordinator"
                :width="40"
                active-color="#38bc9d"
                inactive-color="#999999"
                :active-value="'GeneralCoordinator'"
            >
            </el-switch> -->
            <!-- v-if="switchGenCoordinator == 'GeneralCoordinator'" -->
            <p class="edit-members-role-list">
                <span
                    v-for="( item, index) in selectedGeneralCoordinatorCustomerList"
                    :key="'mrn_' + index"
                >
                    <i class="members-role-name">{{ item.name }}</i>
                    <i
                        class="el-icon-circle-close"
                        @click="handleGeneralCoordinatorCustomer(index)"
                    ></i>
                </span>
            </p>
            <p class="edit-members-role-select">
                <span>选择公司</span>
                <!-- :disabled="switchGenCoordinator !== 'GeneralCoordinator'" -->
                <el-select
                    v-model="generalCoordinatorValue"
                    placeholder="请选择"
                    size="mini"
                    
                    @change="handleSelectGeneralCoordinatorCustomer"
                    filterable
                >
                    <el-option
                        class="members-role-select-item"
                        v-for="(item,idx) in generalCoordinatorList"
                        :title="item.name"
                        :key="'mrsi_' + idx"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </p>
        </div>

        <div class="item business-line-coordinator" v-if="isBusinessCoordinator">
            <span class="switch-text">业务线协调人</span>
            <!-- <el-switch
                v-model="switchBusinessLineCoordinator"
                :width="40"
                active-color="#38bc9d"
                inactive-color="#999999"
                :active-value="'BusinessCoordinator'"
            >
            </el-switch> -->
            <!-- v-if="switchBusinessLineCoordinator == 'BusinessCoordinator'" -->
            <p
                class="edit-members-role-list"
                
            >
                <span
                    v-for="(item, index) in selectedBusinessLineCoordinatorList"
                    :key="'mrn2_' + index"
                >
                    <i class="members-role-name">{{ item.parentName + '-' + item.label }}</i>
                    <i
                        class="el-icon-circle-close"
                        @click="handleBusinessLineCoordinator(index)"
                    ></i>
                </span>
            </p>
            <p class="edit-members-role-select">
                <span>选择事业线</span>
                <!-- :disabled="switchBusinessLineCoordinator !== 'BusinessCoordinator'" -->
                <el-cascader
                    v-model="businessLineCoordinatorValue"
                    
                    :options="businessLineCoordinatorList"
                    @change="handleChange"
                    filterable
                >
                </el-cascader>
            </p>
        </div>

        <!-- <div class="item region-hrbp" v-if="baseRoleCode == 'BRHRBP'"> -->
        <div class="item region-hrbp" v-if="isLimitedTeamData">
            <span class="switch-text">有限团队数据</span>
            <!-- <el-switch
                v-model="switchRegionHRBP"
                :width="40"
                active-color="#38bc9d"
                inactive-color="#999999"
                :active-value="'LRHRBP'"
            >
            </el-switch> -->
            <!-- v-if="switchRegionHRBP == 'LRHRBP'" -->
            <p
                class="edit-members-role-list"
                
            >   
                <span v-if="teamIds.includes('0')">
                    <i class="members-role-name">全部团队</i>
                    <i
                        class="el-icon-circle-close"
                        @click="handleTeam('-1')"
                    ></i>
                </span>
                <span
                    v-else
                    v-for="(item, index) in selectedTeamList"
                    :key="'mrn3_' + index"
                >
                    <i class="members-role-name">{{ item.label }}</i>
                    <i
                        class="el-icon-circle-close"
                        @click="handleTeam(index)"
                    ></i>
                </span>
            </p>
            <p class="edit-members-role-select">
                <span>选择团队</span>
                <el-popover
                    v-loading="teamTreeLoading"
                    class="select-popover"
                    popper-class="team-select-tree-popover"
                    placement="bottom-start"
                    width="386"
                    trigger="click"
                    @click.stop="() => {}"
                    v-model="teamTreePopoverVisible"
                >
                    <el-tree 
                        :data="teamData" 
                        :props="defaultProps" 
                        @node-click="handleNodeClick" 
                        :highlight-current="false"
                        :expand-on-click-node="false"
                        node-key="teamId"
                        :default-expanded-keys="['0']"
                    >
                        <span class="custom-tree-node" slot-scope="{ node, data }" :class="teamIds.includes(data.teamId) ? 'selected' : ''">
                            <span :title="node.label">{{ node.label }}</span>
                            <!-- <i>({{data.membersCount}})</i> -->
                        </span>
                    </el-tree>
                    <!-- :disabled="switchRegionHRBP !== 'LRHRBP'" -->
                    <el-input
                        slot="reference"
                        :readonly="true"
                        class="team-input"
                        placeholder="请选择"
                        suffix-icon="el-icon-arrow-down"
                        
                    ></el-input>
                </el-popover>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button :loading="editLoading" @click="handleClose"
                >取消
            </el-button>
            <el-button
                type="primary"
                :loading="editLoading"
                @click="confirmHandle"
                >确定
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
import CustomerListService from "#/js/service/customerListService.js";
import UserPermissionService from "#/js/service/userPermissionService.js";
import { member as memberUrl } from '#/js/config/api.json';
import { 
    getAuthRolePrivilegeList,
    authUserRoleEdit,
    getAuthRoleDetail,
 } from "#/js/service/commonService.js";

export default {
    data() {
        return {
            teamTreePopoverVisible: false,
            teamTreeLoading: false,
            defaultProps: {
                children: 'childTeams',
                label: 'teamName'
            },
            teamIds: [],

            dialogVisible: false,
            loading: false,
            editLoading: false,
            // baseRoleCode: "HeadHunters",
            baseRoleCode: [],
            rolesArr: [
                {
                    roleName: "猎头",
                    roleCode: "HeadHunters",
                },
                {
                    roleName: "财务",
                    roleCode: "Financial",
                },
                {
                    roleName: "行政",
                    roleCode: "Executive",
                },
                {
                    roleName: "内控",
                    roleCode: "InternalControl",
                },
                {
                    roleName: "平台运营",
                    roleCode: "PlatformOperationer",
                },
                {
                    roleName: "HRBP",
                    roleCode: "BRHRBP",
                },
                // {
                //     roleName: "数据分析",
                //     roleCode: "DataAnalyst",
                // },
            ],
            rolesCFArr: [
                {
                    roleName: "数据分析",
                    roleCode: "DataAnalyst",
                },
            ],
            switchOrguditor: "0",
            switchGenCoordinator: "0",
            switchBusinessLineCoordinator: "0",
            switchRegionHRBP: "0",
            // switchOrguditor: undefined,
            // switchGenCoordinator: undefined,
            extendRoleList: [],
            index: 0,
            data: {},
            departmentReviewerValue: "",
            generalCoordinatorValue: "",
            // businessLineCoordinatorValue: "",
            businessLineCoordinatorValue: [],
            departmentReviewerList: [],
            generalCoordinatorList: [],
            businessLineCoordinatorList: [],
            teamData: [],
            teamTreeObj: [],
            selectedDepartmentReviewerCustomerList: [],
            selectedGeneralCoordinatorCustomerList: [],
            selectedBusinessLineCoordinatorList: [],
            selectedTeamList: [],
        };
    },
    computed: {
        isCFuser(){
            return this.$store.state.user.userInfo.isCFUser;
        },

        currentPrivilegeList() {
            if(this.baseRoleCode.length>0 && this.rolesArr.length>0) {
                let res = [];
                this.baseRoleCode.forEach(it => {
                    let role = this.rolesArr.find(it2 => it2.roleCode == it);
                    if(role) {
                        res = res.concat(role.privilegeList);
                    }
                })
                // return this.rolesArr.find(it => it.roleCode == this.baseRoleCode).privilegeList;
                return res;
            }
            return [];
        },
      
        isLimitedTeamData() {
            if(this.currentPrivilegeList?.length>0) {
                return this.currentPrivilegeList.findIndex(it => it?.privilegeCode == 'LimitedTeamData') > -1;
            }
            return false;
        },

        // 组织架构管理（审批） DepartmentManageAudit
        isDepartmentManageAudit() {
            if(this.currentPrivilegeList?.length>0) {
                return this.currentPrivilegeList.findIndex(it => it?.privilegeCode == 'DepartmentReviewer') > -1;
            }
            return false;
        },

        // 综合协调人 GeneralCoordinator
        isGeneralCoordinator() {
            if(this.currentPrivilegeList?.length>0) {
                return this.currentPrivilegeList.findIndex(it => it?.privilegeCode == 'GeneralCoordinator') > -1;
            }
            return false;
        },

        // 业务线协调人 BusinessCoordinator
        isBusinessCoordinator() {
            if(this.currentPrivilegeList?.length>0) {
                return this.currentPrivilegeList.findIndex(it => it?.privilegeCode == 'BusinessCoordinator') > -1;
            }
            return false;
        },

    },
    mounted() {
        
    },
    methods: {
        fetchAuthRoleDetail(userId) {
            this.loading = true;
            getAuthRoleDetail({userId: userId})
                .then(data => {
                    console.log("🚀 ~ fetchAuthRoleDetail ~ res:", data)

                    this.selectedDepartmentReviewerCustomerList = (
                        data.departmentReviewerCustomerList || []
                    ).map((item) => {
                        return item;
                    });
                    this.selectedGeneralCoordinatorCustomerList = (
                        data.generalCoordinatorCustomerList || []
                    ).map((item) => {
                        return item;
                    });
                    // 已选择了的事业线数据
                    // data.businessCoordinatorList.forEach((item) => {
                    //     this.selectedBusinessLineCoordinatorList = item.departmentList.map(el => {
                    //         return {
                    //             value: el.id,
                    //             label: el.name,
                    //             parentId: item.customerId,
                    //             parentName: item.customerName
                    //         };
                    //     })
                    // })
                    // console.log(this.selectedBusinessLineCoordinatorList);
                    this.teamIds = (data.limitedTeamList || []).map(it => it.id);
                    this.selectedTeamList = (data.limitedTeamList || []).map(it => ({value: it.id, label: it.name}));

                    if (data.roleList.length > 0) {
                        this.baseRoleCode = data.roleList.filter(it => (!['LimitedTeamData', 'DepartmentManageAudit','GeneralCoordinator','BusinessCoordinator'].includes(it.code))).map(it => it.code) || [];
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        show({ index, data } = {}) {
            console.log("🚀 ~ show ~ index, data:", index, data)
            this.index = index;
            this.data = data;
            this.fetchAuthRoleDetail(data.userId);
            
                // data.roleList.forEach((item) => {
                    // 成员基础角色（猎头、财务、行政、内控）,单选
                    // if (
                    //     item.code == "HeadHunters" ||
                    //     item.code == "Financial" ||
                    //     item.code == "Executive" ||
                    //     item.code == "InternalControl" ||
                    //     item.code == "PlatformOperationer" || 
                    //     item.code == "BRHRBP" || 
                    //     item.code == "DataAnalyst"
                    // ) {
                    //     this.baseRoleCode = [item.code];
                    // } 
                    // // 组织架构审核人
                    // if (item.code == "DepartmentReviewer") {
                    //     this.switchOrguditor = item.code;
                    // }
                    // // 综合协调人
                    // if (item.code == "GeneralCoordinator") {
                    //     this.switchGenCoordinator = item.code;
                    // }
                    // // 业务线协调人
                    // if (item.code == "BusinessCoordinator") {
                    //     this.switchBusinessLineCoordinator = item.code;
                    // }
                    // // 区域HRBP
                    // if (item.code == "LRHRBP") {
                    //     this.switchRegionHRBP = item.code;
                    // }
                // });
                // if (!this.switchOrguditor) {
                //     this.switchOrguditor = "0";
                // }
                // if (!this.switchGenCoordinator) {
                //     this.switchGenCoordinator = "0";
                // }
                // console.log("组织架构审核人" + this.switchOrguditor);
                // console.log("综合协调人" + this.switchGenCoordinator);
            // }
            // console.log(this.switchOrguditor)
            // console.log(this.switchGenCoordinator)
            this.dialogVisible = true;
            this.getMembersRoleList();
            this.getBusinessSelectList();
            this.getTeamTree();
            this.getRoleList();
        },
        getRoleList() {
            const params = {
                offset: 0,
                pageSize: 100,
            };
            getAuthRolePrivilegeList(params)
                .then(res => {
                    this.rolesArr = res || [];
                });
        },
        // 关闭编辑弹窗
        handleClose() {
            this.baseRoleCode = [];
            this.switchOrguditor = "0";
            this.switchGenCoordinator = "0";
            this.switchBusinessLineCoordinator = "0";
            this.switchRegionHRBP = "0";
            this.departmentReviewerValue = "";
            this.generalCoordinatorValue = "";
            // this.businessLineCoordinatorValue = "";
            this.businessLineCoordinatorValue = [];
            this.selectedDepartmentReviewerCustomerList = [];
            this.selectedGeneralCoordinatorCustomerList = [];
            this.selectedBusinessLineCoordinatorList = [];
            this.teamIds = [];
            this.selectedTeamList = [];
            this.dialogVisible = false;
        },

        // 获取公司列表
        getMembersRoleList() {
            // this.loading = true;
            CustomerListService.getMyCompanyCustomers()
                .then((res) => {
                    // this.loading = false;
                    res = res || [];
                    let list = res.map((item) => {
                        return {
                            id: item.customerId,
                            name: item.customerName,
                        };
                    });
                    // console.log(list)
                    this.departmentReviewerList = list;
                    this.generalCoordinatorList = list;
                    // 组织架构审核人列表数据过滤（已选择的组织架构审核人客户不出现在选择的下拉列表中）
                    this.departmentReviewerList = this.departmentReviewerList.filter((item)=>
                        !this.selectedDepartmentReviewerCustomerList.some((i)=>
                            i.id == item.id
                        )
                    )
                    // 综合协调人列表数据过滤（已选择的综合协调人客户不出现在选择的下拉列表中）
                    this.generalCoordinatorList = this.generalCoordinatorList.filter((item)=>
                        !this.selectedGeneralCoordinatorCustomerList.some((i)=>
                            i.id == item.id
                        )
                    )
                    // console.log(this.departmentReviewerList)
                    // console.log(this.generalCoordinatorList)
                })
                .catch((err) => {
                    // this.loading = false;
                });
        },

        // 获取事业线选择列表
        getBusinessSelectList() {
            // businessLineCoordinatorList
            UserPermissionService.getBusinessLineList({
                userId: this.data.userId
            })
                .then((res) => {
                    // console.log(res);
                    // this.businessLineCoordinatorList = res;
                    res.extraData.forEach(item => {
                        item.departmentList.forEach(subItem => {
                            this.selectedBusinessLineCoordinatorList.push({
                                value: subItem.id,
                                label: subItem.name,
                                parentId: item.customerId,
                                parentName: item.customerName
                            });
                        });
                    });

                    this.businessLineCoordinatorList = res.data.map(item => {
                        let tempDepartmentList = []; 
                        item.departmentList.forEach(el => {
                            const index = this.selectedBusinessLineCoordinatorList.findIndex(sItem => sItem.value == el.id);
                            if(index < 0) {
                                tempDepartmentList.push({
                                    value: el.id,
                                    label: el.name,
                                });
                            }
                        });
                        return {
                            value: item.customerId,
                            label: item.customerName,
                            children: tempDepartmentList,
                        };
                    });

                    // 数据过滤，选择了的事业线，不出现在事业线选择的列表中
                    // this.businessLineCoordinatorList = this.businessLineCoordinatorList.map(item => {
                    //     let tempChildren = item.children.filter(el => {
                    //         // console.log(el);
                    //         return !this.selectedBusinessLineCoordinatorList.some(i => {
                    //             // console.log(i);
                    //             return i.value == el.value;
                    //         })
                    //     })
                    //     // console.log(tempChildren);
                    //     item.children = tempChildren;
                    //     return item;
                    // })
                    // console.log(this.businessLineCoordinatorList);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        
        // 获取团队树
        getTeamTree() {
            this.teamTreeLoading = true;
            _request({
                url: memberUrl.teams.replace('%p', 2),
                method: 'GET'
            }).then(res => {
                // console.log(res);
                // console.log([res]);
                this.teamData = [res];
                this.teamTreeObj = res;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.teamTreeLoading = false;
            });
        },
        recursion(type, tree, arr) {
            if(type == 'id') {
                arr.push(tree.teamId);
            } else {
                arr.push({
                    value: tree.teamId,
                    label: tree.teamName,
                });
            }
            if (tree.childTeams.length > 0) {
                tree.childTeams.forEach(child => {
                    this.recursion(type, child, arr);
                });
            }
        },
        handleNodeClick(data) {
            console.log(data);

            if(data.teamId == '0') {
                this.teamIds = [];
                this.selectedTeamList = [];
                this.recursion('id', this.teamTreeObj, this.teamIds);
                this.recursion('obj', this.teamTreeObj, this.selectedTeamList);

                // this.teamTreePopoverVisible = false;

            } else {
                // 避免重复push：已选择过的不让再进行选择
                let hasTeamId = this.teamIds.includes(data.teamId);
                console.log(hasTeamId);
                if(!hasTeamId) {
                    this.teamIds.push(data.teamId);
                    
                    this.selectedTeamList.push({
                        value: data.teamId,
                        label: data.teamName,
                    });
                    // this.teamTreePopoverVisible = false;
                }
            }
            // console.log(this.teamIds);
            // console.log(this.selectedTeamList);
        },
        handleTeam(index) {
            console.log(index);
            if(index == '-1') {
                this.teamIds = [];
                this.selectedTeamList = [];
            } else {
                this.selectedTeamList.splice(index, 1);
                this.teamIds = this.selectedTeamList.map(el => el.value);
            }
        },

        // 根据名称拼音进行排序
        clientOrder(data){
            // console.log(data)
            if(data == 'departmentReviewerList'){
                data = this.departmentReviewerList;
            } else if(data == 'generalCoordinatorList'){
                data = this.generalCoordinatorList;
            }
            // console.log(data)
            // let chineseChars=[],chars=[],list= [];
            // data.forEach(item => {
            //     //判断是否为中文
            //     if(/^[\u4e00-\u9fa5]*$/.test(item.name.charAt(0))) {
            //         chineseChars.push(item);  //姓名首字符为中文的
            //     } else {
            //         chars.push(item);  //姓名首字符非中文的(字母，数字)
            //     }
            // })
            // // 非中文a-z排序
            // chars.sort((a,b) => a.name.charCodeAt(0)-b.name.charCodeAt(0));
            // // 中文a-z排序
            // chineseChars.sort((a,b) => a.name.localeCompare(b.name));
            // list = chars.concat(chineseChars); //list为最终想要的数组
            // data = list;
            // 非中文按照a-z排序
            data.sort((a,b) => a.name.charCodeAt(0)-b.name.charCodeAt(0));
            // 中文按照a-z排序
            data.sort((a,b) => a.name.localeCompare(b.name));
            // console.log(data);
        },

        // 点击删除组织架构审核人
        handleDepartmentReviewerCustomer(index) {
            console.log("🚀 ~ handleDepartmentReviewerCustomer ~ index:", index)
            this.departmentReviewerValue = "";
            // 删除所选的客户之后，删除的客户要重新出现在选择的下拉列表中
            // console.log(this.selectedDepartmentReviewerCustomerList[index]);
            this.departmentReviewerList.push(this.selectedDepartmentReviewerCustomerList[index]);
            // console.log(this.departmentReviewerList)

            // push方法是push到最后一位，所以要把新的this.departmentReviewerList数组根据名字排序
            // // 非中文按照a-z排序
            // this.departmentReviewerList.sort((a,b) => a.name.charCodeAt(0)-b.name.charCodeAt(0));
            // // 中文按照a-z排序
            // this.departmentReviewerList.sort((a,b) => a.name.localeCompare(b.name));
            // // console.log(this.departmentReviewerList)
            console.log("🚀 ~ handleDepartmentReviewerCustomer ~ this.selectedDepartmentReviewerCustomerList[index]:", this.selectedDepartmentReviewerCustomerList[index])
            this.clientOrder('departmentReviewerList');
console.log(`3----1`)
            this.selectedDepartmentReviewerCustomerList.splice(index, 1);
            console.log(`3----2`)
        },
        // 点击删除综合协调人
        handleGeneralCoordinatorCustomer(index) {
            this.generalCoordinatorValue = "";
            // 删除所选的客户之后，删除的客户要重新出现在选择的下拉列表中
            // console.log(this.selectedGeneralCoordinatorCustomerList[index]);
            this.generalCoordinatorList.push(this.selectedGeneralCoordinatorCustomerList[index]);
            // console.log(this.generalCoordinatorList)
            // push方法是push到最后一位，所以要把新的this.generalCoordinatorList数组根据名字排序
            this.clientOrder('generalCoordinatorList');

            this.selectedGeneralCoordinatorCustomerList.splice(index, 1);
        },
        // 点击删除业务线协调人
        handleBusinessLineCoordinator(index) {
            // this.businessLineCoordinatorValue = "";
            this.businessLineCoordinatorValue = [];
            // 删除所选的事业线之后，删除的事业线要重新出现在选择的列表中
            this.businessLineCoordinatorList = this.businessLineCoordinatorList.map(item => {
                // console.log('item');
                let tempSelectItem = {
                    value: this.selectedBusinessLineCoordinatorList[index].value,
                    label: this.selectedBusinessLineCoordinatorList[index].label,
                }
                // console.log(this.selectedBusinessLineCoordinatorList);
                if(this.selectedBusinessLineCoordinatorList[index].parentId == item.value) {
                    // console.log(111);
                    item.children.push(tempSelectItem);
                }
                return item;
            })
            // console.log(this.businessLineCoordinatorList);

            this.selectedBusinessLineCoordinatorList.splice(index, 1);
        },
        
        // 选择公司（组织架构审核人）
        handleSelectDepartmentReviewerCustomer(id) {
            let hasSelected = false;
            this.selectedDepartmentReviewerCustomerList.some((item) => {
                if (item.id == id) {
                    hasSelected = true;
                    return true;
                }
            });
            if (hasSelected) {
                return false;
            }
            let customer = this.departmentReviewerList.filter((item) => {
                return item.id == id;
            })[0];
            this.selectedDepartmentReviewerCustomerList.push(customer);
            this.departmentReviewerValue = "";
            this.departmentReviewerList = this.departmentReviewerList.filter((item)=>
                !this.selectedDepartmentReviewerCustomerList.some((i)=>
                    i.id == item.id
                )
            )
        },
        // 选择公司（综合协调人）
        handleSelectGeneralCoordinatorCustomer(id) {
            let hasSelected = false;
            this.selectedGeneralCoordinatorCustomerList.some((item) => {
                if (item.id == id) {
                    hasSelected = true;
                    return true;
                }
            });
            if (hasSelected) {
                return false;
            }
            let customer = this.generalCoordinatorList.filter((item) => {
                return item.id == id;
            })[0];
            this.selectedGeneralCoordinatorCustomerList.push(customer);
            this.generalCoordinatorValue = "";
            this.generalCoordinatorList = this.generalCoordinatorList.filter((item)=>
                !this.selectedGeneralCoordinatorCustomerList.some((i)=>
                    i.id == item.id
                )
            )
        },
        // 选择事业线（业务协调人）
        handleChange(value) {
            // console.log(value);
            let businessLine;
            this.businessLineCoordinatorList.some((item) => {
                let secondItem = item.children.filter(el => {
                    return el.value == value[1];
                })
                // console.log(secondItem);
                businessLine = {
                    ...secondItem[0],
                    parentId: item.value,
                    parentName: item.label
                }
                return item.value == value[0];
            })
            // console.log('businessLine------------');
            // console.log(businessLine);
            this.selectedBusinessLineCoordinatorList.push(businessLine);
            // this.businessLineCoordinatorValue = "";
            this.businessLineCoordinatorValue = [];
            // 选择了的事业线，不出现在选择的列表中
            this.businessLineCoordinatorList = this.businessLineCoordinatorList.map(item => {
                let tempChildren = item.children.filter(el => {
                    // console.log(el);
                    return !this.selectedBusinessLineCoordinatorList.some(i => {
                        // console.log(i);
                        return i.value == el.value;
                    })
                })
                // console.log(tempChildren);
                item.children = tempChildren;
                return item;
            })
            // console.log(this.businessLineCoordinatorList);
        },

        // 点击确定按钮，提交
        confirmHandle() {
            if (this.isDepartmentManageAudit && this.selectedDepartmentReviewerCustomerList.length == 0) {
                shortTips("组织架构审核人-请至少选择一个公司！");
                return false;
            }
            if (this.isGeneralCoordinator && this.selectedGeneralCoordinatorCustomerList.length == 0) {
                shortTips("综合协调人-请至少选择一个公司！");
                return false;
            }
            if (this.isBusinessCoordinator && this.selectedBusinessLineCoordinatorList.length == 0) {
                shortTips("业务线协调人-请至少选择一个公司！");
                return false;
            }
            if (this.isLimitedTeamData && this.selectedTeamList.length == 0) {
                shortTips("有限团队数据-请至少选择一个团队！");
                return false;
            }
            let departmentReviewerCustomerIds =
                this.selectedDepartmentReviewerCustomerList.map((item) => item.id);
            let generalCoordinatorCustomerIds =
                this.selectedGeneralCoordinatorCustomerList.map((item) => item.id);
            // 事业线客户id
            let businessCoordinatorCustomerIds =
                this.selectedBusinessLineCoordinatorList.map((item) => item.parentId);
            // 事业线部门id
            let businessCoordinatorCustomerDepartmentIds =
                this.selectedBusinessLineCoordinatorList.map((item) => item.value);

            // 清空上一次push的值
            this.extendRoleList = [];
            // 组织架构审核人
            if(this.isDepartmentManageAudit){
                let generalCoordinatorObj = {
                    customerIds: departmentReviewerCustomerIds,
                    // roleCode: this.switchOrguditor,
                    roleCode: 'DepartmentReviewer',
                }
                this.extendRoleList.push(generalCoordinatorObj);
            }
            // 综合协调人
            if(this.isGeneralCoordinator){
                let generalCoordinatorObj = {
                    customerIds: generalCoordinatorCustomerIds,
                    roleCode: 'GeneralCoordinator',
                }
                this.extendRoleList.push(generalCoordinatorObj);
            }
            // 业务协调人
            // if(this.switchBusinessLineCoordinator=="BusinessCoordinator"){
            //     let businessCoordinatorObj = {
            //         customerIds: businessCoordinatorCustomerIds,
            //         customerDepartmentIds: businessCoordinatorCustomerDepartmentIds,
            //         roleCode: this.switchBusinessLineCoordinator,
            //     }
            //     this.extendRoleList.push(businessCoordinatorObj);
            // }
            if(this.isBusinessCoordinator){
                let customerList = this.selectedBusinessLineCoordinatorList.map((item) => ({
                        customerId: item.parentId,
                        customerDepartmentId: item.value
                    }));
                // console.log(customerList);
                
                let businessCoordinatorObj = {
                    customerList: customerList,
                    roleCode: 'BusinessCoordinator',
                }
                this.extendRoleList.push(businessCoordinatorObj);
            }
            // 区域HRBP
            // if(this.switchRegionHRBP=="LRHRBP") {
            // if(this.baseRoleCode == 'BRHRBP' && this.switchRegionHRBP=="LRHRBP") {
            if(this.isLimitedTeamData) {
                // 删除teamIds数组中的第一个数据0
                let tempTeamIds = this.teamIds.filter(el => {
                    return el != '0';
                });
                // console.log(tempTeamIds);
                let teamObj = {
                    // teamList: tempTeamIds,
                    // roleCode: 'LimitedTeamData'
                    privilegeTeamList: tempTeamIds,
                    privilegeCode: 'LimitedTeamData',
                }
                this.extendRoleList.push(teamObj);
            }
            // console.log(this.extendRoleList);

            // UserPermissionService.editMembersRole({
            //     userId: this.data.userId,
            //     baseRoleCode: this.baseRoleCode,
            //     extendRoleList: this.extendRoleList,
            // })

            this.editLoading = true;
            // let params = {
            //     userId: this.data.userId,
            //     baseRoleCode: this.baseRoleCode,
            //     extendRoleList: this.extendRoleList,
            // };
            let params = this.baseRoleCode.map(it => ({
                    userId: this.data.userId,
                    baseRoleCode: it,
                    extendRoleList: this.extendRoleList,
                }));
            authUserRoleEdit(params)
                .then((res) => {
                    this.editLoading = false;
                    shortTips("修改成功");
                    this.dialogVisible = false;
                    // 修改完成之后重新刷新列表
                    this.$emit("update-members-role");
                    // 当前登录用户角色变更，刷新页面
                    if(this.data.userId == this.$store.state.user.userInfo.id) {
                        window.location.reload();
                    }
                    // this.updateUserInfo();
                })
                .catch((err) => {
                    this.editLoading = false;
                });
        },
        updateUserInfo() {
            _request({
                url: '/user/userInfo',
                method: 'GET',
                baseURL: "LbdJavaApi",
                javaProject: "performance",
                throwBusinessError: true
            }).then(res => {
                this.$store.dispatch('setUserInfo', res);
            }).catch( err => {
                console.log(err);
            });
        },
        
        handleRoleChange(...arg){
            this.selectedDepartmentReviewerCustomerList = [];
            this.selectedGeneralCoordinatorCustomerList = [];
            this.selectedBusinessLineCoordinatorList = [];
            this.selectedTeamList = [];
        },
    },
};
</script>

<style lang="scss">
.el-cascader-node__label {
    max-width: 200px;
}

.edit-members-role-dialog {
    .el-dialog__body {
        padding: 0;
        height: auto;
        max-height: 500px;
        overflow-y: scroll;
    }
    .set-role {
        // padding: 0 20px;
        // height: 75px;
        // line-height: 75px;
        padding: 20px 20px 20px;
        border-bottom: 1px solid #f6f6f6;

        .el-radio {
            margin-bottom: 20px;
        }
    }
    .item {
        padding: 15px 20px 20px;
        border-bottom: 1px solid #f6f6f6;
        &.region-hrbp {
            border-bottom: none;
        }

        .switch-text {
            display: inline-block;
            margin-right: 22px;
        }

        .edit-members-role-list {
            margin-top: 15px;
            span {
                position: relative;
                display: inline-block;
                max-width: 100%;
                padding: 0 7px;
                margin-right: 10px;
                margin-bottom: 10px;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                color: $primary;
                background-color: #ebf8f5;
                .members-role-name {
                    display: inline-block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .el-icon-circle-close {
                    display: none;
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    font-size: 14px;
                    color: $primary;
                    background-color: #fff;
                    cursor: pointer;
                }
                &:hover .el-icon-circle-close {
                    display: block;
                }
            }
        }

        .edit-members-role-select {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            > span {
                margin-right: 10px;
            }
            .el-select {
                flex: 1;
                flex-grow: 1;
            }
            .el-cascader {
                flex: 1;
                flex-grow: 1;
                line-height: 28px;
                .el-input .el-input__inner {
                    height: 28px;
                }
                .el-input__icon {
                    line-height: 28px;
                }
            }
            .select-popover {
                margin: 0;
                flex: 1;
                flex-grow: 1;
                .team-input {
                    .el-input__inner {
                        height: 28px;
                        line-height: 28px;
                    }
                    .el-input__icon {
                        line-height: 28px;
                    }
                }
            }
        }
    }
}

.el-select-dropdown__item.members-role-select-item {
    width: 384px;
}

.team-select-tree-popover {
    max-height: 267px;
    overflow-y: scroll;

    .el-tree-node {
        overflow: unset!important;

        > .el-tree-node__children {
            overflow: unset!important;

            .el-tree-node__content:hover {
                background-color: #E7F7F3;
            }

            .custom-tree-node {
                display: flex;
                max-width: 100%;
                min-width: 160px;

                &.active {
                    color: $primary;
                }
                &.selected {
                    color: #999;
                    cursor: not-allowed;
                }
                
                span {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                i {
                    padding-right: 20px;
                }
            }
        }
    }
}
</style>
