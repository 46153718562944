import {
    permission as permissionUrl,
    operationPlatform as operationPlatformUrl
} from '#/js/config/javaApi.json';


export const getAuthPrivilegeList = (params = {}) => {
    return _request({
        method: 'GET',
        url: permissionUrl.auth_privilege_list,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const getAuthRolePrivilegeList = (params = {}) => {
    return _request({
        method: 'GET',
        url: permissionUrl.auth_role_privilege_list,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const authRolePrivilegeEdit = (params = {}) => {
    return _request({
        method: 'POST',
        url: permissionUrl.auth_role_privilege_edit,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const authRoleAdd = (params = {}) => {
    return _request({
        method: 'POST',
        url: permissionUrl.auth_role_add,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};


export const getAuthUserRoleList = (params = {}) => {
    return _request({
        method: 'GET',
        url: permissionUrl.auth_user_role_list,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const authUserRoleEdit = (params = {}) => {
    return _request({
        method: 'POST',
        url: permissionUrl.auth_user_role_edit,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const authRoleDelete = (params = {}) => {
    return _request({
        method: 'DELETE',
        url: permissionUrl.auth_role_delete + `?roleId=${params.roleId}`,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
        throwBusinessError: true,
    });
};


export const getAuthRoleDetail = (params = {}) => {
    return _request({
        method: 'GET',
        url: permissionUrl.auth_role_detail,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const getLexiconText = (params = {}) => {
    return _request({
        method: 'GET',
        url: operationPlatformUrl.get_es_words,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};

export const saveLexiconText = (params = {}) => {
    return _request({
        method: 'POST',
        url: operationPlatformUrl.save_es_words,
        baseURL: 'LbdJavaApi',
        javaProject: 'javaCommon',
        data: params,
    });
};
